// Import globals
@import "./_global/global.less";

// Hide bootstrap-switches until they are initialized
.switch {
  visibility: hidden;
}

// Styled icon
.icon-image {
  text-align: center;
  margin: 10px;
  padding: @content-padding-large;
  display: inline-block;
}

.tt-menu {
  min-width: 340px;
}

.bootstrap-tagsinput {
  border-bottom: 1px solid transparent;
  border-bottom-color: #ddd;
}

.bootstrap-tagsinput input {
  min-width: 180px;
}