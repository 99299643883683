/* ------------------------------------------------------------------------------
 *
 *  # Global less file
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
[v-cloak] {
  display: none;
}
.switch {
  visibility: hidden;
}
.icon-image {
  text-align: center;
  margin: 10px;
  padding: 20px;
  display: inline-block;
}
.tt-menu {
  min-width: 340px;
}
.bootstrap-tagsinput {
  border-bottom: 1px solid transparent;
  border-bottom-color: #ddd;
}
.bootstrap-tagsinput input {
  min-width: 180px;
}
